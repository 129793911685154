<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { breakpointsTailwind } from '@vueuse/core'
import { getSBImageSize, getSBLink } from './helpers'
import type { MultilinkStoryblok, SbImageStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbImageStoryblok }>()

const link = computed(() => getSBLink(props.blok.link as MultilinkStoryblok))
const img = useImage()

const linkComponent = computed(() => {
  if (link.value) {
    return resolveComponent('NuxtLink')
  }
  return 'span'
})

const maxWidth = computed(() => {
  if (props.blok.maxWidth) {
    return Number(props.blok.maxWidth)
  }
  return null
})

const absoluteHeight = computed(() => props.blok.height ? Number(props.blok.height) * 4 : null)
const absoluteWidth = computed(() => props.blok.width ? Number(props.blok.width) * 4 : null)

const imageSize = computed(() => {
  // Absolute value
  if (absoluteHeight.value && absoluteWidth.value) {
    return {
      width: absoluteWidth.value,
      height: absoluteHeight.value,
    }
  }

  // Original image size
  const [width = 0, height = 0] = getSBImageSize(props.blok.value?.filename)

  if (absoluteHeight.value) {
    const scaledWidth = (width / height) * absoluteHeight.value
    return {
      width: scaledWidth,
      height: absoluteHeight.value,
    }
  }
  if (absoluteWidth.value) {
    const scaledHeight = (height / width) * absoluteWidth.value

    return {
      width: absoluteWidth.value,
      height: scaledHeight,
    }
  }

  // Image size adjusted to maxWidth
  const cappedWidth = Math.min(width, maxWidth.value ?? width)
  // Height of image scaled to cappedWidth
  const scaledHeight = (height / width) * cappedWidth

  return {
    width: cappedWidth,
    height: scaledHeight,
  }
})

const imageStyle = computed<CSSProperties>(() => {
  if (!absoluteHeight.value && !absoluteWidth.value) {
    return {}
  }

  return {
    width: imageSize.value.width ? `${imageSize.value.width}px` : '',
    height: imageSize.value.height ? `${imageSize.value.height}px` : '',
    objectFit: 'contain',
  }
})

const sources = computed(() => {
  return [
    { image: props.blok.mobileImage, screen: breakpointsTailwind.sm },
    { image: props.blok.tabletImage, screen: breakpointsTailwind.lg },
    { image: props.blok.smallDesktopImage, screen: breakpointsTailwind['2xl'] },
  ]
    .filter(({ image }) => !!image?.filename)
    .map(({ image, screen }) => ({
      image: {
        ...image,
        filename: img.getSizes(image!.filename, {
          quality: 100,
          width: screen,
          format: 'webp',
        }),
      },
      screen,
    }))
})
</script>

<template>
  <div
    v-editable="blok"
    class="flex"
    :class="{
      'justify-center': blok.align === 'center',
      'justify-start': blok.align === 'left',
      'justify-end': blok.align === 'right',
    }"
    :style="imageStyle"
  >
    <Component
      :is="linkComponent"
      class="w-full"
      :style="{
        maxWidth: maxWidth ? `${maxWidth}px` : '',
      }"
      :target="blok.link?.target"
      :to="link"
    >
      <picture>
        <template v-for="source in sources" :key="source.screen">
          <source
            :media="`(max-width: ${source.screen}px)`"
            :srcset="source.image.filename!.srcset"
          >
        </template>

        <img
          :alt="blok.value.alt"
          class="'h-auto' block w-full"
          :height="imageSize.height"
          loading="lazy"
          preload
          :src="blok.value.filename"
          :style="imageStyle"
          :width="imageSize.width"
        >
      </picture>
    </Component>
  </div>
</template>
